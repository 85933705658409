import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer.jsx";
import HomepageNavbar from "../../components/HomepageNavbar/HomepageNavbar.jsx";
import GeneralConst from "../../consts/GeneralConst.js";
import GeneralStrings from "../../strings/GeneralStrings.json";
import classNames from "../../utilities/classNames";
import "./OurVisionPage.scss";

const TITLE = `Our Vision | ${GeneralConst.SITE_NAME}`;

const OurVisionPage = () => {
  const ourVisionStrings = GeneralStrings.OUR_VISION;
  const principles = ourVisionStrings.PRINCIPLES;

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <HomepageNavbar transparentNavbar={false} />
      <div className="our-vision" id="our-vision">
        <div className="container section-container">
          <div className="row padding-top-50">
            <h2 className="header text-center">
              {ourVisionStrings.OUR_VISION}
            </h2>
            <p className="description text-center">
              {ourVisionStrings.DESCRIPTION}
            </p>
            <iframe
              className="video"
              width="300"
              height="500"
              src="https://www.youtube.com/embed/79xvAsRyfuk?si=f_k1-xQV4nK-fcka"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
            <hr />
            <h4 className="sub-header text-center">
              {ourVisionStrings.OUR_PRINCIPLES}
            </h4>
            <div className="container">
              <div className="row text-center principles-container">
                {principles.map((principle) => (
                  <div className="col-md-4">
                    <div className="principle-item">
                      <span className="fa-stack fa-4x">
                        <i className="fas fa-circle fa-stack-2x principle-item-icon"></i>
                        <i
                          {...classNames(
                            "fa-solid fa-stack-1x fa-inverse",
                            principle.icon
                          )}
                        ></i>
                      </span>
                      <h4 className="principle-title margin-top-20 margin-bottom-20">
                        {principle.title}
                      </h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OurVisionPage;
