import React from "react";
import { Helmet } from "react-helmet";
import EventCard from "../../components/EventCard/EventCard.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import HomepageNavbar from "../../components/HomepageNavbar/HomepageNavbar.jsx";
import GeneralConst from "../../consts/GeneralConst.js";
import logo from "../../images/backgrounds/black-runner.jpg";
import ExperianFlyer from "../../images/events/experian-flyer.jpg";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./EventsPage.scss";

const TITLE = `Events | ${GeneralConst.SITE_NAME}`;

const EventsPage = () => {
  const eventsStrings = GeneralStrings.EVENTS;

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <HomepageNavbar transparentNavbar={false} />
      <div className="events" id="events">
        <div className="container section-container">
          <div className="row padding-top-50">
            <h2 className="header text-center">{eventsStrings.EVENTS}</h2>
            <div className="col-sm-12 col-md-4">
              <EventCard
                event={{
                  date: "2024-08-10",
                  imgLoc: logo,
                  link: "https://www.eventbrite.com/e/running-for-real-estate-party-with-a-purpose-rooftop-garden-bash-tickets-932079925147?aff=oddtdtcreator",
                  title: "Party with a Purpose Rooftop Garden Bash",
                }}
              />
            </div>
            <div className="col-sm-12 col-md-4">
              <EventCard
                event={{
                  date: "2024-11-02",
                  imgLoc: logo,
                  link: "https://www.eventbrite.com/e/experience-with-experian-tickets-1054473712259",
                  title: "Experience with Experian",
                }}
              />
            </div>
            <div className="col-sm-12 col-md-4">
              <a href={ExperianFlyer} target="_blank" rel="noreferrer">
                <img
                  className="event-image"
                  src={ExperianFlyer}
                  alt="Experian Flyer"
                />
              </a>
            </div>
          </div>
          <hr />
          <div className="row padding-top-50">
            <h4 className="header text-center">{eventsStrings.PAST_EVENTS}</h4>
            <ul className="past-events-list">
              <li>
                <a
                  target="_blank"
                  href="https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Ffox59.com%2Fmorning-news%2Fbroad-ripple-park-family-center-to-host-financial-literacy-event%2F&data=05%7C02%7CDMiller%40fox59.com%7C7c978e16edd64c1b289108dcfcbd13aa%7C9e5488e2e83844f6886cc7608242767e%7C0%7C0%7C638663136238607521%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=pYl8h1g4GeV09mHN2Uws0aWtidqBwSALg%2BFJlU6c%2Bbo%3D&reserved=0"
                  rel="noreferrer"
                >
                  Teaching Financial Literacy
                </a>
              </li>
            </ul>
            {/* <a target="_blank" href="https://fox59.com/video/financial-literacy-event-in-broad-ripple/10182153/">
                Check out our recent event teaching financial literacy!
              </a> */}
          </div>
          <hr />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EventsPage;
